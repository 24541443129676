import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const AppUrlListener = () => {
  const history = useHistory();
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://myplayer.tshoko.fr/reset-password?token=rerze
      // slug = /reset-password?token=rerze
      const slug = event.url.split('.fr').pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
